<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="roles"
      class="elevation-1"
			dense
			:search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Role Access Maintenance</v-toolbar-title>
          <v-spacer></v-spacer>
			<v-text-field
				v-model="search"
				append-icon="search"
				label="Search"
				single-line
				hide-details
			></v-text-field> 
			<vue-excel-xlsx
				:data="roles"
				:columns="columnHeaders"
				:file-name="module_name"
				>
				<v-btn icon alt class="mt-2" :color="roles.length=== 0 ? 'gray' : 'primary'" :disabled="roles.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
			</vue-excel-xlsx>
        </v-toolbar>
      </template>
      <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
    </v-data-table>
    <create-role-access  v-if="role_access > 1" />
  </div>
</template>

<script>
import createRoleAccess from './createRoleAccess.vue';
import { mapGetters } from 'vuex';

export default {
	data(){
		return {
			module_name: 'Role Access',
			role_access: 1,
			nextPageRegularTrx: '',
			search: ''
		};
	},
	components: {
		'create-role-access': createRoleAccess
	},
	computed: {
		...mapGetters( {
			roles: 'roles/roles',
			headers: 'roles/headers',
			columnHeaders: 'roleaccess/columnHeaders',
			editedItem: 'roles/editedItem',
			defaultItem: 'roles/defaultItem',
			dialog: 'roles/dialog',
			editedIndex: 'roles/editedIndex',
			isLoading: 'roles/isLoading',
			valid: 'roles/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('roles/getRoles');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('roles/setEditedIndex', this.roles.indexOf(item));
			// this.$store.dispatch('roles/setEditedItems', Object.assign({}, item));
			this.$store.dispatch('roles/getRolesDetails', item.id);
			this.$store.dispatch('roles/setDialog', true);
			this.$store.dispatch('roles/setValid', true);
			this.$store.dispatch('roles/setformTitle', 'Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: `Remove ${item.roles} from the list?`,
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('roles/setEditedIndex', this.roles.indexOf(item));
					this.$store.dispatch('roles/deleteRoles', item);
				}
			});
		},
		async getRegTrx(){
			this.roles = this.roles.data;
			this.nextPageRegularTrx= this.roles.next_page_url;
			let page = 2;

			while (this.nextPageRegularTrx !== null){
				await this.$store.dispatch('roles/getData', page).then(response => {
					this.nextPageRegularTrx = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.roles.push(details);
					});
				});
				page++;
			}
		}
	}
};
</script>