<template>
	<div class="home">
		<table-role-access />
	</div>
</template>

<script>
import tableRolesAccess from '@/components/pages/maint/roleaccess/tableRoleAccess.vue';

export default {
	components: {
		'table-role-access': tableRolesAccess,
	}
};
</script>

<style>

</style>
