<template>
	<v-dialog v-model="dialog" persistent max-width="70%">
		<template v-slot:activator="{ on }">
			<app-add-button v-on="on" v-bind:module="'roles'"></app-add-button>
		</template>
		<v-form ref="form" v-model="isvalid" lazy-validation>
			<v-card tile>
				<v-toolbar flat dark color="primary">
					<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
					<div class="flex-grow-1"></div>
					<v-btn icon dark @click="close">
						<v-icon>close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<v-container grid-list-md>
						<v-layout wrap>
							<v-flex xs12 sm12 md12>
								<!-- <v-select
									v-model="role_id"
									:items="roles"
									editable
									item-text="roles"
									item-value="id"
									label="Role"
								></v-select> -->
								<v-text-field v-model="roles" label="Role"></v-text-field>
							</v-flex>
							<v-flex md12>
								<v-simple-table dense v-if="details.length !== 0">
									<template v-slot:default>
										<thead>
										<tr>
											<th>#</th>
											<th>Module</th>
											<!-- <th>Module Type</th> -->
											<th>Access</th>
											<th>Action</th>
										</tr>
										</thead>
										<tbody>
										<tr
											v-for="(detail, detail_key) in details"
											:key="detail.detail_key"
										>
											<td>{{ detail_key + 1 }}</td>
											<td>
												<v-autocomplete
													v-model="detail.module_id"
													:items="module"
													editable
													item-text="module_name"
													item-value="id"
												></v-autocomplete>
											</td>
											<!-- <td>
												<v-select
													v-model="detail.module_type"
													:items="module_types"
													editable
													item-text="name"
													item-value="code"
													readonly
												></v-select>
											</td> -->
											<td>
												<v-select
													v-model="detail.access_level"
													:items="access_level"
													editable
													item-text="name"
													item-value="code"
												></v-select>
											</td>
											<td>
											<v-btn icon>
												<v-icon small color="red" @click="deleteAccess(detail_key, detail)">delete</v-icon>
											</v-btn>
											</td>
										</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-text>

				<v-card-actions>					
					<v-btn right dark color="primary" @click="addAccess">
					<v-icon>mdi-plus</v-icon>
					<span>Add Module</span>
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" dark @click="save">Save</v-btn>
					<v-btn color="red darken-1"  text @click="close">Cancel</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
	},
	watch: {
		editedItem: {
			handler(val){
				if(val.length !== 0){
					this.role_id = val.id;
					this.roles = val.roles;
					if(val.roleaccess.length > 0){
						this.details = val.roleaccess;
					}
				}
			}
		}
	},
	data: () => ({
		isvalid: true,
		role_id: '',
		roles: '',
		// module_types: [
		// 	{code: 'F', name: 'File Maintenance'},
		// 	{code: 'T', name: 'Transaction'},
		// 	{code: 'R', name: 'Report'},
		// ],
		access_level: [
			{code: 1, name: 'View'},
			{code: 2, name: 'Insert'},
			{code: 3, name: 'Update'},
			{code: 4, name: 'Delete'},
			{code: 5, name: 'Void'},
		],
		details: [{id: '', module_id: '', access_level: ''}],
		deleted: []
	}),

	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			editedItem: 'roles/editedItem',
			formTitle: 'roles/formTitle',
			valid: 'roles/valid',
			dialog: 'roles/dialog',
			editedIndex: 'roles/editedIndex',
			module: 'module/module',
		})
	},
	mounted () {
		this.$store.dispatch('module/getModule');
	},
	methods: {
		close() {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.role_id = '';
				this.roles = '';
				this.deleted = [];
				this.details= [{module_id: '', access_level: '', id: ''}];
				this.$store.dispatch('roles/setValid', false);
				this.$store.dispatch('roles/setDialog', false);
				this.$store.dispatch('roles/setEditedIndex', -1);
				this.isvalid = false;
			}, 2000);
		},

		async save() {
			this.$store.dispatch('roles/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			let updated = [];
			let roleaccess = [];

			this.details.forEach(det => {
				det.edit_by = this.currUser.id;
				if(det.id){
					updated.push(det);
				}else{
					roleaccess.push(det);
				}
			});

			let data = {'id': this.role_id, 'roles': this.roles, 'roleaccess': roleaccess, 'updated': updated, 'deleted': this.deleted, 'edit_by': this.currUser.id};
			if(this.isvalid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('roles/updateRoles', data);
				} else {
					this.$store.dispatch('roles/saveRoles',data);
				}
				setTimeout(() => {
					this.close();
				}, 
				3000);
			} else {
				this.$store.commit('app/SET_SNACK_BAR', { 
					'status': true, 
					'message': 'Please fill out all the required fields.', 
					'messagetype': 'error' 
				}, { root: true });
			}
		},
		// changeType(key){
		// 	const data = this.module.filter(det => det.id === this.details[key].module_id);
		// 	this.details[key].module_type = data[0].module_type;
		// },
		addAccess() {
			const newRoleAcces = {module_id: '', access_level: ''};
			if(this.role_id){
				newRoleAcces.role_id = this.role_id;
			}
			this.details.push(newRoleAcces);
		},
		deleteAccess(key, detail){
			this.details.filter((detail, index) => {
				if (index === key) {
					this.details.splice(key, 1);
				}
			});
      
			if(detail.id){
				this.deleted.push({id: detail.id});
			}
		}
	}
};
</script>